import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

const SourceCodeVersion = () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            git {
              baseURL
              branch
              longHash
              shortHash
              date
            }
          }
        }
      }
    `}
    render={(data) => (
      <Table size="small">
        <TableBody>
          <TableRow>
            <TableCell style={{ width: '300px' }}>
              <b>Base URL</b>
            </TableCell>
            <TableCell>{data.site.siteMetadata.git.baseURL}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Branch </b>
            </TableCell>
            <TableCell>{data.site.siteMetadata.git.branch}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Long HASH</b>
            </TableCell>
            <TableCell>{data.site.siteMetadata.git.longHash}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Short HASH</b>
            </TableCell>
            <TableCell>{data.site.siteMetadata.git.shortHash}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <b>Date</b>
            </TableCell>
            <TableCell>{data.site.siteMetadata.git.date}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )}
  />
);

export default SourceCodeVersion;
