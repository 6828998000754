import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { Helmet } from 'react-helmet';

import RealTimeBackendCard from '#components/page-content/backend-page/RealTimeBackendCard';
import SessionCard from '#components/page-content/backend-page/SessionCard';
import SourceCodeVersion from '#components/SourceCodeVersion';
import styles from '#utils/styles';

const useStyles = makeStyles(styles);

const PageTitle = 'Backend';

const BackendPage = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{PageTitle}</title>
      </Helmet>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} lg={12}>
          <SessionCard />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <RealTimeBackendCard />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <Card className={classes.Card}>
            <CardHeader title="GIT Version" titleTypographyProps={{ variant: 'h6' }} className={classes.CardHeader} />
            <CardContent className={classes.CardContent}>
              <SourceCodeVersion />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default BackendPage;
